import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.form = this.element;
  }

  send() {
    this.form.requestSubmit();
  }
}
